<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "BetweenEditingAndSubmission",
  components: {
    SuawMainContent,
    SuawParagraph,
    // eslint-disable-next-line vue/no-unused-components
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="BetweenEditingAndSubmission">
      <SuawParagraph text="You’ve just completed your novel, and you can’t wait to get it out to agents or publishers. Whoa — don’t press send yet!" />
      <SuawParagraph text="Even when the manuscript has been edited, rewritten, beta read, edited again, and polished as much as possible, <em>still don’t send it.</em>" />
      <SuawParagraph
        text="The details of submitting a manuscript may not be exciting, but they are crucial if you want a publisher to take your work seriously. Find out exactly what the publisher wants you to submit, and make sure that information is up-to-date, because many publishers change their requirements from year to year. Your manuscript needs to survive in a highly competitive industry, and many great books are rejected because the author didn’t submit their manuscript with more care. Publishers and editors want more with each passing year as competition gets stiffer for prized publication slots."
      />
      <SuawParagraph
        text="I just submitted my seventh book, “Spirit in Time,” to my publisher. Even with six books already published by the same company – actually, <em>because</em> of this – I know how important the submission process is. I never let down my vigilance. My publisher changes their process slightly every year in order to make their selection method easier, and also to ensure they are selecting authors willing to work hard for their books. My publisher isn’t the only one who does this. Publishers and agents are becoming more ruthless in weeding out authors who believe their job is done as soon as the book is written."
      />
      <SuawParagraph
        text="How do they do this weeding? Many publishers now require the submission of a marketing plan with a manuscript. It may not need to be anything particularly formal – just a statement of what you intend to do once the book is launched. Will you hold a launch party, or advertise, or submit to reviewers?"
      />
      <SuawParagraph
        text="They may want to know if you have a website, a blog, and are active on social media. If you already have a book out, self-published or not, they may also want to know how many copies have sold."
      />
      <SuawParagraph
        text="If you are new to all of this and haven’t done any of these things, it’s never too early to start laying the groundwork. This can feel overwhelming, but try to take on one project at a time, starting with a website, and add another element every six months or so. If you have no idea how you will market a book, don’t worry about it. Not having all the pieces in place doesn’t mean a guaranteed rejection — new authors get contracts all the time. But, like a resume, each added line of experience helps your manuscript rise to the top of the to-be-read pile."
      />
      <SuawParagraph
        text="Because I went through the submission process with my publisher within the past year, and have several books out already, I felt ready this time around. I had my vigorously edited manuscript, one-page synopsis, and marketing information ready. I sent all of this to my editor and crossed my fingers."
      />
      <SuawParagraph
        text="My editor sent back a note: they wanted a blurb and tagline up front too. This was work I typically do after a signed contract; if the publisher doesn’t like the book, there’s no point in writing a blurb for the back cover. However, I got to work on the request immediately."
      />
      <SuawParagraph
        text="Here’s why this extra component didn’t bother me: A good blurb cuts to the heart of the book. This is the selling pitch, and if an author can’t write a blurb about their own work, then how can they sell it? I have a friend who actually writes her blurb well before she finishes her book because it gives her focus. She told me once that writing the blurb made her rethink how to write the story. So if you’re familiar with common requests from publishers, like blurbs and taglines, it may help you focus your project even more."
      />
      <SuawParagraph
        text="I’m waiting to see if the submission of my time travel book, “Spirit in Time,” results in a contract. I followed the publisher’s guidelines, and know I’ve done everything I can. We all spend countless hours writing our books; it’s worth the effort to compile an excellent submission package."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
